@import "./main";

.page404Section {
  height: 100vh;
  img {
    display: block;
    margin: 20px auto;
    @include media("<tablet") {
      width: 100%;
    }
  }
}
