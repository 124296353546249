@import "./styles/main";
html,
#root {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  all: unset;
  font-size: 16px;
  line-height: 18px;
  color: $white;
  text-transform: uppercase;
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  img {
    height: 25px;
  }
  &.default {
    background: $green;
    @include btnHoverAnimationPurple;
  }
  &.submit {
    background: $green;
    width: fit-content;
    margin: 0 auto;
    @include btnHoverAnimationPurple;
  }
  &.secondary {
    background: $background;
    @include btnHoverAnimationGrey;
  }
}

.line {
  border: 1px solid $purple;
}
.container {
  max-width: 90%;
  // max-width: 80rem;
  margin: 0 auto;
}

.darkBackground {
  background: $background;
}

.languageSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin-right: 20px;
  span {
    color: $white;
    font-weight: 600;
    cursor: pointer;
    &.active {
      color: $green;
    }
  }
}
.CookieConsent {
  @include media("<=custom640") {
    justify-content: center !important;
    .cookieDescription {
      white-space: break-spaces;
    }
  }
}

// custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $background;
  // -webkit-border-radius: 10px;
  // border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: $purple;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: $backgroundLighter;
}
