@import "./main";

.heroSection {
  background: $backgroundLighter;
  padding: 1rem 0;
  .container {
    display: flex;
    gap: 30px;
    justify-content: center;
    @include media("<phoneS") {
      gap: 10px;
    }
  }
  .nameContainer {
    height: fit-content;
    margin: auto 0;
    h1 {
      @include fontSize36;
      color: $white;
      display: flex;
      flex-direction: column;
      gap: 5px;
      span {
        color: $green;
      }
    }
    .job {
      color: $white;
    }
  }
  img {
    @include media("<=tablet") {
      width: 200px;
    }
    @include media("<=phoneM") {
      width: 120px;
    }
  }
}
.programmerSection {
  background: $background;
  padding: 1rem 0;
  .container {
    display: flex;
    justify-content: center;
    gap: 30px;
    @include media("<=tablet") {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .meContainer {
    height: fit-content;
    margin: auto 0;
    h1 {
      @include fontSize36;
      color: $white;
      span {
        color: $green;
        margin-left: 5px;
      }
    }
    .aboutShortDescription {
      @include fontSize16;
      color: $white;
      max-width: 600px;
      display: block;
      margin-bottom: 20px;
    }
  }
  img {
    @include media("<=tablet") {
      width: 250px;
    }
  }
}
