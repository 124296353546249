@import "./main";

.App {
  background-color: $background;
  min-height: 100vh;
  color: $primary;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .AppContent {
    flex: 1 0 0;
    background-color: $backgroundLighter;
  }
}
