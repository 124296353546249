// colors
$primary: #1b75ba;
$secondary: #b2afaf;
$white: #ffffff;
$black: #000000;
$background: #1d222a;
$backgroundLighter: #222831;
$green: #00adb5;
$purple: #7e1aba;
$gray: grey;
$hoverColor: #334155;
$red: #a00c0c;
