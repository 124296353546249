@import "./main";

.projectSection {
  background: $backgroundLighter;
  padding: 20px 0;
  .container {
    max-width: 90%;
  }
  h1 {
    font-size: 36px;
    line-height: 38px;
    color: $green;
    margin-bottom: 20px;
  }
  p {
    color: $white;
    font-size: 16px;
    line-height: 18px;
  }
  .projectsList {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 20px;
    @include media("<=phone") {
      justify-content: center;
    }
  }
}

.projectCard {
  border-radius: 14px;
  background: $hoverColor;
  figure {
    all: unset;
    display: block;
    margin: 20px;
    position: relative;
    padding: 0;
    width: 300px;
    height: 180px;
    overflow: hidden;
    img {
      width: 300px;
      height: 170px;
      object-fit: cover;
      object-position: top left;
    }
    .projectType {
      position: absolute;
      top: 10px;
      right: 10px;
      background: $green;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 16px;
      line-height: 18px;
      color: white;
    }
  }
  article {
    color: $white;
    padding: 15px;
    h2 {
      margin: 0 0 20px 0;
    }
    .btn {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:hover {
    background-color: $hoverColor;
  }
}

.projectDetailsSection {
  padding: 50px 0;
  background: $backgroundLighter;
  h1 {
    font-size: 36px;
    line-height: 38px;
    color: $green;
    margin: 0 auto 30px auto;
    width: fit-content;
  }
  .content {
    .imgDetailsContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 1rem;
      @include media("<desktop") {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: stretch;
      }
      .imgContainer {
        border: 1px solid $hoverColor;
        border-radius: 14px;
        img {
          width: 100%;
          object-fit: scale-down;
          border-radius: 14px;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        background: $hoverColor;
        padding: 1.5rem;
        border-radius: 14px;
        color: $white;
        p {
          margin: 0;
          color: $white;
        }
        h2 {
          color: $green;
        }
        .btnContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          gap: 20px;
          margin-bottom: 10px;
          @include media("<=phoneM") {
            flex-direction: column;
          }
        }
        .technologiesList {
          all: unset;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          li {
            all: unset;
            font-weight: 600;
            color: $white;
            &:not(:last-of-type)::after {
              content: ",";
            }
          }
        }
      }
    }
    .projectDetails {
      margin-top: 2rem;
      h2 {
        font-size: 24px;
        line-height: 28px;
        color: $white;
      }
      p {
        color: $white;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
