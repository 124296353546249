@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/GothamBook.woff") format("woff");
}
@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 400;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/GothamBookItalic.woff")
      format("woff");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/GothamMedium.woff") format("woff");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 500;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/GothamMedium_1.woff") format("woff");
}
@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: 500;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/GothamMediumItalic.woff")
      format("woff");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/GothamBold.woff") format("woff");
}
@font-face {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 700;
  src: local("Gotham"),
    url("https://fonts.cdnfonts.com/s/14898/Gotham-Bold.woff") format("woff");
}
