@import "./main";

.aboutTextSection {
  padding: 20px 40px;
  background: $backgroundLighter;
  color: $white;
  @include media("<=custom640") {
    padding: 10px 20px;
  }
  h1 {
    font-size: 36px;
    line-height: 38px;
    span {
      color: $green;
      margin-left: 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 22px;
    max-width: 1240px;
  }
}
.techStackSection {
  padding: 20px 40px;
  background: $background;
  color: $white;
  @include media("<=custom640") {
    padding: 10px 20px;
  }
  h1 {
    font-size: 36px;
    line-height: 38px;
    span {
      color: $green;
      margin-left: 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }
  .stack1,
  .stack2,
  .stack3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 25px;
    margin: 30px 0;
    .stackCard {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      background: $backgroundLighter;
      border-radius: 14px;
      padding: 15px;
      min-height: 115px;
      justify-content: flex-end;
      img {
        width: 80px;
        margin: auto 0;
      }
      span {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }
    }
    @include media("<custom1200") {
      gap: 25px;
    }
    @include media("<=phoneM") {
      justify-content: center;
    }
  }
}

.educationSection,
.jobExperienceSection {
  padding: 20px 40px;
  background: $backgroundLighter;
  color: $white;
  @include media("<=custom640") {
    padding: 10px 20px;
  }
  @include media("<=phoneS") {
    padding: 10px;
  }
  h2 {
    @include fontSize36;
    @include media("<=phoneS") {
      @include fontSize32;
    }
    span {
      color: $green;
      margin-left: 10px;
    }
  }
  .timelineContainer {
    margin: 30px auto;
    max-width: 750px;
    .chronoCard {
      color: $background;
      span {
        @include media("<=custom640") {
          display: none;
        }
      }
    }
    .chronoText {
      align-items: flex-start;
    }
    .chronoTitle {
      @include fontSize18;
      color: $white;
      background: transparent;
      text-align: center;
      @include media("<phoneS") {
        @include fontSize14;
      }
    }
    .chronoItem {
      h2 {
        @include fontSize20;
        margin: 0;
      }
      h3 {
        @include fontSize18;
        margin: 0;
        color: $green;
      }
    }

    .cAIJdN {
      @include media("<=phone") {
        padding: 0;
      }
    }
    .cRshbF {
      @include media("<=phone") {
        padding: 0;
        gap: 10px;
      }
    }
  }
}
