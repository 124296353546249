@import "./main";
.contactPage {
  background: $backgroundLighter;
  padding: 1rem 0;
  .container {
    h1 {
      font-size: 36px;
      line-height: 38px;
      color: $white;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      color: $white;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 5%;
      @include media("<=tablet") {
        justify-content: space-between;
      }
      @include media("<=custom640") {
        flex-direction: column-reverse;
        gap: 0;
      }
      img {
        height: 350px;
        @include media("<=phoneM") {
          width: 100%;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 15px;
        flex-basis: 300px;
        @include media("<=custom640") {
          width: 80%;
        }
        .row {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          gap: 10px;
          label {
            font-size: 14px;
            line-height: 16px;
            color: $white;
          }
          input {
            all: unset;
            border-radius: 14px;
            background: $white;
            padding: 5px 10px;
            height: 30px;
            color: $background;
          }
          textarea {
            all: unset;
            height: 200px;
            border-radius: 14px;
            background: $white;
            padding: 5px 10px;
            color: $background;
          }
        }
      }
    }
  }
}
