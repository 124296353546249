@import "./main";

header {
  background: $background;
  padding: 10px 50px;
  ul {
    all: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
    li {
      @include fontSize16;
      @include fontNormal700;
      all: unset;
      color: $white;
      cursor: pointer;
      &.logo {
        img {
          height: 60px;
        }
      }
      &.active {
        color: $green;
      }
      &.languageSelectorItem {
        margin-left: auto;
      }
      &:not(.languageSelectorItem, .logo) {
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 3px;
          background: $purple;
          transition: width 0.3s;
        }
        &:hover:after {
          width: 100%;
        }
      }
    }
  }
  @include media("<tablet") {
    display: none !important;
  }
}
.responsiveMenuContainer {
  background: $background;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 10px;
  img {
    height: 60px;
    z-index: 3;
  }
  .responsiveMenu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 15px;
    .burgerMenu {
      width: 2rem;
      height: 2rem;
      z-index: 3;
      display: none;

      @include media("<tablet") {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
      }

      div {
        width: 2rem;
        height: 0.25rem;
        background-color: $green;
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;

        &:nth-child(1) {
          transform: rotate(0);
        }

        &:nth-child(2) {
          transform: translateX(0);
          opacity: 1;
        }

        &:nth-child(3) {
          transform: rotate(0);
        }
      }
      &.open > div {
        width: 2rem;
        height: 0.25rem;
        background-color: $green;
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;
        &:nth-child(1) {
          transform: rotate(45deg) !important;
        }

        &:nth-child(2) {
          transform: translateX(100%) !important;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) !important;
        }
      }
    }
    .burgerMenuList {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      z-index: 2;
      flex-flow: column nowrap;
      background-color: $background;
      position: fixed;
      transform: translateX(100%);
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 0;
      gap: 25px;
      transition: transform 0.3s ease-in-out;
      &.open {
        transform: translateX(0);
      }

      li {
        @include fontSize32;
        @include fontNormal500;
        color: $white;
      }
    }
  }
  @include media(">=tablet") {
    display: none !important;
  }
}
