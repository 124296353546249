@import "./fonts";

// FontFamily
@mixin fontNormal {
  font-family: -apple-system, BlinkMacSystemFont, "Gotham", sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin fontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Gotham", sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin fontNormal500 {
  font-family: -apple-system, BlinkMacSystemFont, "Gotham", sans-serif;
  font-style: normal;
  font-weight: 500;
}

@mixin fontItalic500 {
  font-family: -apple-system, BlinkMacSystemFont, "Gotham", sans-serif;
  font-style: italic;
  font-weight: 500;
}

@mixin fontNormal700 {
  font-family: -apple-system, BlinkMacSystemFont, "Gotham", sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin fontItalic700 {
  font-family: -apple-system, BlinkMacSystemFont, "Gotham", sans-serif;
  font-style: italic;
  font-weight: 700;
}

// FontSizes
@mixin fontSize14 {
  font-size: 14px;
  line-height: 18px;
}
@mixin fontSize16 {
  font-size: 16px;
  line-height: 22px;
}
@mixin fontSize18 {
  font-size: 18px;
  line-height: 24px;
}
@mixin fontSize20 {
  font-size: 20px;
  line-height: 26px;
}

@mixin fontSize32 {
  font-size: 32px;
  line-height: 38px;
}
@mixin fontSize36 {
  font-size: 36px;
  line-height: 42px;
}

@mixin btnHoverAnimationGrey {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 200ms ease-in-out;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: $secondary;
    z-index: -1;
    transition: width 200ms ease-in-out;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 110%;
    }
  }
}
@mixin btnHoverAnimationPurple {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 200ms ease-in-out;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: $purple;
    z-index: -1;
    transition: width 200ms ease-in-out;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 110%;
    }
  }
}
