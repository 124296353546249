@import "./main";

.getInTouch {
  background: $backgroundLighter;
  padding: 1rem 0;
  h2 {
    font-size: 36px;
    line-height: 38px;
    color: $white;
    span {
      color: $green;
      margin-left: 5px;
    }
    &.flex {
      @include media("<custom640") {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }
    }
  }
  p {
    color: $white;
  }
  .connections {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin: 30px auto;

    .connectionItem {
      all: unset;
      cursor: pointer;
      border-radius: 14px;
      height: 120px;
      width: 200px;
      background: $background;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: $white;
      img {
        height: 50px;
      }
      &:hover {
        background-color: $hoverColor;
      }
      @include media("<=phoneM") {
        width: 120px;
      }
      @include media(">phoneM", "<tablet") {
        width: 170px;
      }
    }
    @include media("<tablet") {
      flex-wrap: wrap;
    }
    @include media("<=custom640") {
      gap: 10px;
    }
  }
}
